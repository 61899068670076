"use client"
import { cn } from "@repo/utils/utils"
import { AudioLines } from "lucide-react"

import { memo, useCallback, useEffect, useState } from "react"


let Howl: any = undefined as any

type AudioButtonProps = {
	className?: string
	classNames?: {
		wrapper?: string
		icon?: string
	}
	size?: number
	text?: string
}
const TextAudioButton = memo<AudioButtonProps>(
	({ className, classNames, size = 18, text }) => {
		const [howler, setHowler] = useState<any>()
		const [isLoading, setIsLoading] = useState(false)
		const [isPlaying, setIsPlaying] = useState(false)
		const handleClick = useCallback(() => {
			if (isPlaying) {
				return
			}
			if (howler) {
				howler.play()
				return
			}
			if (text?.length) {
				setIsLoading(true)
				const url = `${location.origin}/api/tools/chinese-names-generator/audio/${text}`
				// @ts-ignore
				const sound = new Howl({
					src: [url],
					format: ["mp3"],
					onload: () => {
						setIsLoading(false)
						sound.play()
					},
					onplay: () => {
						setIsPlaying(true)
					},
					onend: () => {
						setIsPlaying(false)
					},
					onstop: () => {
						setIsPlaying(false)
					},
					onloaderror: () => {
						setIsLoading(false)
					},
				})
				setHowler(sound)
			}
		}, [text])


		useEffect(() => {
			import("howler").then(mod => {
				Howl = mod.Howl
			})
			return () => {
				if (Howl) {
					Howl = undefined as any
				}
			}
		}, [])

		return (
			<div
				className={cn(
					"inline-flex cursor-pointer transition-all delay-100 duration-500 ease-in-out",
					className,
					classNames?.wrapper,
				)}
			>
				{isLoading ? (
					<svg
						className="animate-spin -ml-1 mr-3 h-4 w-4 text-primary"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
					>
						<circle
							className="opacity-25"
							cx="12"
							cy="12"
							r="10"
							stroke="currentColor"
							strokeWidth="3"
						></circle>
						<path
							className="opacity-75"
							fill="currentColor"
							d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
						></path>
					</svg>
				) : (
					<AudioLines
						size={size}
						className={cn(
							"hover:scale-110 hover:text-black",
							{
								"text-primary": isPlaying,
								"text-gray-500": !isPlaying,
							},
							classNames?.icon,
						)}
						onClick={handleClick}
					/>
				)}
			</div>
		)
	},
)

TextAudioButton.displayName = "TextAudioButton"

export default TextAudioButton
