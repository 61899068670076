// 标记避免代码被打包到客户端
"use server"
import RPCClient from "@alicloud/pop-sdk"

const client = new RPCClient({
	accessKeyId: "LTAI5t8DUvd5yLW8PgbUrLNx",
	accessKeySecret: "udHubfwQVTX5lPqym3AazsQ6O6oLCd",
	endpoint: "https://nls-meta.cn-shanghai.aliyuncs.com",
	apiVersion: "2019-02-28",
})

const appKey = "O6YGlP8bZz1JRj5K"
const tokenHolder = {
	token: "",
	expire: 0,
}

export async function getToken() {
	if (
		tokenHolder.token.length &&
		tokenHolder.expire > Math.floor(Date.now() / 1000)
	) {
		return Promise.resolve(tokenHolder.token)
	}
	return client
		.request("CreateToken", {})
		.then((res: any) => {
			console.log("Refresh AliCloud Token = " + res.Token.Id)
			console.log("Refresh AliCloud Token ExpireTime = " + res.Token.ExpireTime)
			tokenHolder.token = res.Token.Id
			tokenHolder.expire = res.Token.ExpireTime
			return tokenHolder.token
		})
		.catch((err) => {
			throw new Error("Get Aliyun Token error: " + err)
		})
}

export async function synthesizeSpeech(text: string, format = "mp3") {
	const url = "https://nls-gateway-cn-shanghai.aliyuncs.com/stream/v1/tts"
	const token = await getToken()

	// 构建请求体
	const requestBody = {
		appkey: appKey,
		text: text,
		token: token, // 可选
		format: format,
		speech_rate: -400,
		voice: "ruoxi",
	}

	try {
		const response = await fetch(url, {
			method: "POST",
			// @ts-ignore
			headers: {
				"Content-Type": "application/json",
				"X-NLS-Token": token, // 可选
			},
			body: JSON.stringify(requestBody),
		})
		if (!response.ok) {
			return await response.json() // 返回错误信息
		}
		// 返回音频数据
		return await response.arrayBuffer()
	} catch (error) {
		throw error // 其他错误抛出
	}
}
