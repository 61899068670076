"use client"

import { cn } from "@repo/utils/utils"
import type { HanziWriterOptions } from "hanzi-writer"
import { first } from "radash"
import { useEffect, useId } from "react"
import { useMedia } from "react-use"

type HanziBlockProps = {
	text: string
	size?: number
	className?: string
	classNames?: {
		wrapper?: string
		base?: string
		buttons?: string
	}
	animateBtnText?: string
	options?: HanziWriterOptions
}

export default function HanziBlock({
	text,
	size = 100,
	className,
	classNames,
	options = {} as HanziWriterOptions,
}: HanziBlockProps) {
	const id = useId()
    const isMobile = useMedia("(min-width: 640px)")
	// const [writer, setWriter] = useState<HanziWriter>()
	
	useEffect(() => {
		if (!text) {
			return
		}
		
		let hw = undefined as any
        import("hanzi-writer").then((mod) => {
			hw = mod.default.create(id, first(text.split(""))!, {
				width: size,
				height: size,
				...options,
			})
			
			hw.loopCharacterAnimation().then(() => {
				//ignore
			})
		})
		
		// setWriter(hw)
		return () => {
			// 销毁，避免重复渲染
			if(hw) {
				hw.target.node.remove()
			}
			// setWriter(undefined)
		}
	}, [text, isMobile])
	return (
		<>
			<div
				className={cn(
					"hanzi-block inline-block px-2",
					className,
					classNames?.wrapper,
				)}
			>
				<div
					id={id}
					className={cn("border border-gray-200 rounded", classNames?.base)}
				></div>
			</div>
		</>
	)
}
